<template id="tmplForgotPassword">
  <div id="divForgotPassoword" class="app flex-row align-items-center">
    <div id="divParent" class="container vld-parent">
      <loading
        id="ldngForgotPasswordLoading"
        :active.sync="isLoading"
        :is-full-page="true"
        color="#114C8E"
      ></loading>
      <b-row id="rowResetPass" class="justify-content-center">
        <b-col id="colResetPass" md="8" lg="5" sm="10" xs="12">
          <b-card-group id="crdGrpResetPass">
            <b-card id="crdResetPass" no-body class="p-4">
              <b-card-body>
                <b-form id="txtForgotPass" novalidate>
                  <h3 id="hdrForgotPass" class="text-center">Forgot Password</h3>
                  <p id="pResetPass" class="text-center">
                    Please enter your email to reset password.
                  </p>
                  <b-form-group id="txtGrpEmail" label="Email" label-for="txtEmail">
                    <b-form-input
                      id="txtEmail"
                      type="text"
                      class="form-control"
                      v-model.trim="email"
                      :state="
                        inputValidations.chkState('email', $v) == false
                          ? false
                          : null
                      "
                      @keyup.enter="resetPassword()"
                    />
                    <b-form-invalid-feedback id="txtFeedBackVal"
                      :state="inputValidations.chkState('email', $v)"
                      >Valid email is required.</b-form-invalid-feedback
                    >
                  </b-form-group>
                  <b-row id="rowResetPassButton" class="text-center">
                    <b-col id="colResetPassButton">
                      <b-button
                        id="btnResetPass"
                        variant="primary"
                        class="px-4"
                        @click="resetPassword()"
                        >Reset Password</b-button
                      >
                    </b-col>
                    <b-col id="colLogin" cols="6" class="text-right">
                      <b-button
                        id="btnSignIn"
                        variant="link"
                        class="px-0"
                        @click="$router.push({ name: 'login' })"
                        >Sign in?</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
//import { chkState } from "@/util/input-validation";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import inputValidations from "@/shared/utils/input-validation";
import { handleRequestError } from "@/shared/utils/response-error-handler";

  export default {
  name: "ForgotPassword.vue",
  components: {
    Loading
  },
  data() {
    return {
      email: null,
      isLoading: false,
      validation: null,
      inputValidations: inputValidations
    };
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      try {
        this.$v.$touch();
        if (!this.$v.$invalid) {
          await this.$store.dispatch("user/sendResetPaswordEmail", this.email);
          this.email = "";
					this.$snotify.success("Check your email.", "Success");
        }
        this.$v.$reset();
      } catch (error) {
        var errorMessage = handleRequestError(error);
        this.$snotify.error(errorMessage, "Error");
      }

      this.isLoading = false;
    }
  },
  validations: {
    email: {
      required,
      email
    }
  }
};
</script>

<style scoped></style>
